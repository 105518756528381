@media (min-width: 992px) {
  .w-lg-auto {
    width: auto !important;
  }
}

@media (max-width: 1360px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 1200px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 992px) {
  .main-header {
    .navbar-collapse {
      padding-top: 20px;
    }

    .navbar-nav {
      .nav-item {
        .nav-link {
          padding: 4px 0;
        }
      }
    }

    &__logo {
      width: 60px;
    }
  }
  .hero-title {
    font-size: 80px;
  }
  .section {
    &__text {
      font-size: 16px;
    }
  }
  .section-home-hero {
    &__inner {
      &::before {
        display: none;
      }
    }

    .hero-title {
      margin-left: 0;
    }
  }
  .service-box {
    margin-left: 0;
    &__icon-wrap {
      margin-right: 40px;
      width: 180px;
      i {
        font-size: 120px;
      }
    }
  }
  .history-year {
    font-size: 80px;
  }

  .section-advantage {
    min-height: 300px;
    background-size: cover;
    background-position: -100px top;
    &--right {
      background-position: 100px top;
    }
  }
}
@media (max-width: 768px) {
  .section {
    padding: 40px 0;
    &__title {
      font-size: 30px;

      margin-bottom: 40px;
      &::after {
        bottom: -20px;
      }
    }
  }
  .address-box {
    flex-wrap: wrap;
    margin-left: 0;
    margin-right: 0;
    &__item {
      width: 100%;
      padding: 0;
      margin-bottom: 30px;
      &:not(:last-child) {
        border-right: none;
      }

      &-title {
        font-size: 24px;

        margin-bottom: 12px;
      }
      &-element {
        font-size: 16px;
        &:not(:last-child) {
          margin-bottom: 12px;
        }

        &-title {
          font-weight: bold;
        }
      }
    }
  }
  .big-stacked-icon {
    &__icon-wrap {
      i {
        font-size: 50px;
      }
    }
    &__value {
      font-size: 38px;

      margin-bottom: 18px;
    }
    &__title {
      font-size: 20px;
    }
  }
  .service-box {
    &__icon-wrap {
      margin-right: 40px;
      width: 110px;
      i {
        font-size: 80px;
      }
    }
  }
  .history-year {
    text-align: center;
    line-height: 100%;
    margin-bottom: 50px;
  }

  .contact-form {
    .ty-button {
      width: 100%;
      max-width: none;
    }
  }

  .section-advantage {
    min-height: none;
    background-size: cover;
    margin: 0;
    // background-image: none !important;
    background: var(--gradient-primary) !important;

    .section__title {
      color: #000;
    }
  }

  .mission-carousel-wrap {
    position: static;
    width: 100%;
    margin-top: 60px;
    margin-bottom: -40px;
    height: 400px;
  }
}
@media (max-width: 576px) {
  .main-footer {
    text-align: center;
    .copyright-text {
      text-align: center;
      width: 100%;
    }
  }

  .hero-title {
    font-size: 40px;
  }
  .section-home-hero {
    min-height: 420px;
    background-attachment: unset;
    .hero-title {
      font-size: 40px;
    }
  }
  .section-inner-hero {
    background-attachment: unset;
  }
  .section-inner-hero {
    min-height: 250px;
  }
  .service-box {
    margin-bottom: 20px;
    &__icon-wrap {
      margin-right: 10px;
      width: 60px;
      i {
        font-size: 40px;
      }
    }
  }
  .history-box {
    .history-title {
      font-size: 18px;
    }
    .history-text {
      font-size: 16px;
    }
  }
}
@media (max-width: 350px) {
  .service-box {
    .section__title {
      font-size: 24px;
    }
  }
}
